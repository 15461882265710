import styled from '@emotion/styled'
import { Icon } from 'components/icon'
import { IconWrapper } from 'components/iconWrapper/IconWrapper'
import { HStack, Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { LeftColumnItem as LeftColumnItemProperties } from 'logic/navigation/estatePlanningCard/types'
import { Trans } from 'next-i18next'
import Link from 'next/link'
import { FC } from 'react'
import { colors } from 'utils/colors'

export const LeftColumnItem: FC<{ item: LeftColumnItemProperties }> = ({ item }) => (
  <Link href={item.url} legacyBehavior>
    <SColumns gap="0.75rem">
      <IconWrapper css={{ flexShrink: 0 }} backgroundColor={item.color}>
        <Icon name={item.icon} />
      </IconWrapper>
      <Stack gap={[0, '0.5rem']}>
        <Text
          variant={['bodyLarge', 'bodyLargeBold']}
          css={{ color: colors.gray[100] }}
          data-is-title
        >
          <Trans i18nKey={`common.navigation.estate_planning.${item.type}.title`} />
        </Text>
        <Text variant={['bodyMedium', 'bodyLarge']}>
          <Trans i18nKey={`common.navigation.estate_planning.${item.type}.description`} />
        </Text>
      </Stack>
    </SColumns>
  </Link>
)

const SColumns = styled(HStack)({
  alignItems: 'start',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    '*[data-is-title]': {
      textDecoration: 'underline',
    },
  },
})
