import { Icon } from 'components/icon'
import { BasicNavigationContent } from 'components/navigation/BasicNavigationContent'
import { EstatePlanningNavigationContent } from 'components/navigation/estatePlanning/EstatePlanningNavigationContent'
import { PathProps } from 'components/navigation/Path'
import { ProductNavigationContent } from 'components/navigation/ProductNavigationContent'
import { sendSimpleFlowStartedEvent } from 'logic/directive/analytics/sendSimpleFlowStartedEvent'
import { NavigationVariant } from 'logic/navigation/types'
import { Translation } from 'next-i18next'
import { SUPPORT_URL } from 'utils/constants'

export const getLandingPageNavigationStructure = (params: {
  variant: NavigationVariant
  isLogged?: boolean | null
}) => {
  const displayEstatePlanningNavigation =
    params.variant === 'estate-planning' || params.variant === 'general-info'

  const content: PathProps[] = [
    displayEstatePlanningNavigation
      ? {
          name: (
            <Translation>{(t) => t('common.navigation.headers.estate_planning.title')}</Translation>
          ),
          children: <EstatePlanningNavigationContent />,
        }
      : {
          name: <Translation>{(t) => t('common.navigation.headers.products.title')}</Translation>,
          children: <ProductNavigationContent />,
        },
    {
      name: <Translation>{(t) => t('common.navigation.headers.about.title')}</Translation>,
      children: <BasicNavigationContent childPaths={getAboutChildPaths()} />,
    },
    {
      name: <Translation>{(t) => t('common.navigation.headers.resources.title')}</Translation>,

      children: <BasicNavigationContent childPaths={getResourcesChildPaths()} />,
    },
    {
      name: <Translation>{(t) => t('common.navigation.headers.pricing.title')}</Translation>,
      url: '/pricing',
    },
    {
      name: <Translation>{(t) => t('common.navigation.headers.login.title')}</Translation>,
      url: '/login',
      isEncouragedAction: true,
      isAction: true,
      authStatus: false,
    },
    {
      name: <Translation>{(t) => t('common.navigation.headers.get_started.title')}</Translation>,
      url: '/signup',
      isEncouragedAction: true,
      isAction: true,
      authStatus: false,
      actionEvent: () => sendSimpleFlowStartedEvent('not_selected', 'get_started'),
    },
    {
      name: <Translation>{(t) => t('common.navigation.headers.dashboard.title')}</Translation>,
      url: params.variant === 'estate-planning' ? '/me/estate-planning' : '/me/dashboard',
      isEncouragedAction: true,
      isAction: true,
      authStatus: true,
    },
  ]
  return content.filter((p) => p.authStatus == null || p.authStatus === params.isLogged)
}

function getResourcesChildPaths() {
  return [
    {
      name: (
        <Translation>
          {(t) => t('common.navigation.headers.resources.children.estate_planning_guide.title')}
        </Translation>
      ),
      description: (
        <Translation>
          {(t) =>
            t('common.navigation.headers.resources.children.estate_planning_guide.description')
          }
        </Translation>
      ),
      icon: <Icon name="Guide" color="black" />,
      url: '/end-of-life-guide',
    },
    {
      name: (
        <Translation>
          {(t) => t('common.navigation.headers.resources.children.articles.title')}
        </Translation>
      ),
      description: (
        <Translation>
          {(t) => t('common.navigation.headers.resources.children.articles.description')}
        </Translation>
      ),
      icon: <Icon name="TextDocument" color="black" />,
      url: '/articles',
    },
  ]
}

function getAboutChildPaths() {
  return [
    {
      name: (
        <Translation>
          {(t) => t('common.navigation.headers.about.children.about_goodtrust.title')}
        </Translation>
      ),
      description: (
        <Translation>
          {(t) => t('common.navigation.headers.about.children.about_goodtrust.description')}
        </Translation>
      ),
      icon: <Icon name="InfoCircle" color="black" />,
      url: '/about',
    },
    {
      name: (
        <Translation>
          {(t) => t('common.navigation.headers.about.children.for_business.title')}
        </Translation>
      ),
      description: (
        <Translation>
          {(t) => t('common.navigation.headers.about.children.for_business.description')}
        </Translation>
      ),
      icon: <Icon name="Home" color="black" />,
      url: '/enterprise',
    },
    {
      name: (
        <Translation>
          {(t) => t('common.navigation.headers.about.children.security.title')}
        </Translation>
      ),
      description: (
        <Translation>
          {(t) => t('common.navigation.headers.about.children.security.description')}
        </Translation>
      ),
      icon: <Icon name="ShieldCheck" color="black" />,
      url: '/security',
    },
    {
      name: (
        <Translation>
          {(t) => t('common.navigation.headers.about.children.contact_us.title')}
        </Translation>
      ),
      description: (
        <Translation>
          {(t) => t('common.navigation.headers.about.children.contact_us.description')}
        </Translation>
      ),
      icon: <Icon name="Mail" color="black" />,
      url: SUPPORT_URL,
      target: '_blank',
    },
  ]
}
