import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { css } from '@emotion/react'
import {
  LandingPageSectionFragment,
  SteppedTutorialFragment,
} from 'api/contentful/contentful.graphql.types'
import { Button } from 'components/button/Button'
import { ButtonProps, ButtonTone } from 'components/button/types'
import { ContentfulContent } from 'components/content/contentfulContent/ContentfulContent'
import { ThemedLandingPageTextImageSectionProps } from 'components/content/landingPageThemedTextImageSection/LandingPageThemedTextImageSection'
import { ThemedTextImageSectionProps } from 'components/content/themedTextImageSection/ThemedTextImageSection'
import { Icon, IconName } from 'components/icon'
import { ContentfulImage } from 'components/Image'
import { PrimaryLink } from 'components/Link'
import { Stack } from 'components/Stack'
import { Step } from 'components/stepsGuide/StepsGuide'
import { Caption, Text } from 'components/Typography'
import { ReactElement, ReactNode } from 'react'
import { isNonNullable } from 'utils/general'

export type CornerstoneType =
  | 'estate-planning' // directives, will, power of attorney
  | 'digital-protection' // devices, docs & notes, sites & social
  | 'digital-executor' // stop subscriptions, close accounts
  | 'life-stories' // singing portraits, live portraits, future messages, memory timelines

export interface CornerstoneTheme {
  light: string // Used as button border, info section background
  main: string // Used as button and icon background, link color
  transparent: string // Used as page heading background
  tone: ButtonTone
  mainIcon: ReactElement
  icons: ReactElement[]
}

export type CornerstoneTranslationIds =
  | 'estate_planning'
  | 'life_stories'
  | 'digital_executor'
  | 'digital_protection'

export type CornerstoneChildrenTranslationIds =
  | 'trust'
  | 'will'
  | 'funeral'
  | 'power_of_attorney'
  | 'healthcare_directive'
  | 'pet_directive'
  | 'story_portraits'
  | 'singing_portraits'
  | 'live_portraits'
  | 'future_messages'
  | 'digital_vault'
  | 'dashlane_password_manager'
  | 'cyberscout_insurance'
  | 'digital_executor_description'

export interface Cornerstone {
  translationId: CornerstoneTranslationIds
  url: string
  children: {
    translationId: CornerstoneChildrenTranslationIds
    mobileTranslationId?: 'digital_executor_description_mobile'
    url?: string
  }[]
  theme: CornerstoneTheme
}

export const CORNERSTONES: Record<CornerstoneType, Cornerstone> = {
  'estate-planning': {
    translationId: 'estate_planning',
    url: '/estate-planning',
    theme: {
      light: 'var(--c-orange100)',
      main: 'var(--c-orange200)',
      transparent: 'var(--c-orange200-transparent)',
      tone: 'accent',
      mainIcon: <Icon name="Will" />,
      icons: [
        <Icon name="HealthCareDirective" key="HealthCareDirective" />,
        <Icon name="PowerOfAttorney" key="PowerOfAttorney" />,
        <Icon name="FuneralDirective" key="FuneralDirective" />,
        <Icon name="PetDirective" key="PetDirective" />,
        <Icon name="Will" key="Will" />,
      ],
    },
    children: [
      {
        translationId: 'trust',
        url: '/trust',
      },
      {
        translationId: 'will',
        url: '/will',
      },
      {
        translationId: 'funeral',
        url: '/funeral',
      },
      {
        translationId: 'power_of_attorney',
        url: '/financial-power-of-attorney',
      },
      {
        translationId: 'healthcare_directive',
        url: '/advance-health-care-directive',
      },
      {
        translationId: 'pet_directive',
        url: '/pet-directive',
      },
    ],
  },
  'life-stories': {
    translationId: 'life_stories',
    url: '/life-stories',
    theme: {
      light: 'var(--c-green100)',
      main: 'var(--c-green200)',
      transparent: 'var(--c-green200-transparent)',
      tone: 'success',
      mainIcon: <Icon name="LifeStories" />,
      icons: [
        <Icon name="Send" key="Send" />,
        <Icon name="SingingPortrait" key="SingingPortrait" />,
        <Icon name="LivePortrait" key="LivePortrait" />,
        <Icon name="Hourglass" key="Hourglass" />,
      ],
    },
    children: [
      {
        translationId: 'story_portraits',
        url: '/story-portraits',
      },
      {
        translationId: 'singing_portraits',
        url: '/singing-portraits',
      },
      {
        translationId: 'live_portraits',
        url: '/memories',
      },
      {
        translationId: 'future_messages',
        url: '/future-message',
      },
    ],
  },
  'digital-protection': {
    translationId: 'digital_protection',
    url: '/digital-protection',
    theme: {
      light: 'var(--c-blue100)',
      main: 'var(--c-blue500)',
      transparent: 'var(--c-blue500-transparent)',
      tone: 'standard',
      mainIcon: <Icon name="DigitalVault" />,
      icons: [
        <Icon name="PassCode" key="PassCode" />,
        <Icon name="ShieldCheck" key="ShieldCheck" />,
        <Icon name="TextDocument" key="TextDocument" />,
        <Icon name="TrustedContact" key="TrustedContact" />,
      ],
    },
    children: [
      {
        translationId: 'digital_vault',
        url: '/digital-vault',
      },
      {
        translationId: 'dashlane_password_manager',
        url: '/dashlane-password-manager',
      },
      {
        translationId: 'cyberscout_insurance',
        url: '/cyberscout-coverage',
      },
    ],
  },
  'digital-executor': {
    translationId: 'digital_executor',
    url: '/digital-executor',
    theme: {
      light: 'var(--c-gray50)',
      main: 'var(--c-gray100)',
      transparent: 'transparent',
      tone: 'dark',
      mainIcon: <Icon name="Executor" />,
      icons: [],
    },
    children: [
      {
        translationId: 'digital_executor_description',
        mobileTranslationId: 'digital_executor_description_mobile',
      },
    ],
  },
}

export function mapCornerstoneToButtonTone(type: CornerstoneType): ButtonTone {
  return CORNERSTONES[type].theme.tone
}

export const CORNERSTONE_TYPES: CornerstoneType[] = [
  'estate-planning',
  'digital-protection',
  'digital-executor',
  'life-stories',
]

export function isCornerstoneType(value: string): value is CornerstoneType {
  return value in CORNERSTONES
}

export function getCornerstoneGradient(type: CornerstoneType) {
  return `linear-gradient(180deg, ${CORNERSTONES[type].theme.light} 883px, white 1022px)`
}

export const noParagraphMarginCss = css`
  p {
    margin: 0;
  }
`
type SectionSpec<SectionKey extends string> = {
  key: SectionKey
  primaryButton?: Partial<ButtonProps>
  secondaryButton?: Partial<ButtonProps>
  cornerstoneType?: CornerstoneType
  imagePlacement?: 'left' | 'right'
  logoAboveTitle?: ReactNode
  props?: Partial<ThemedTextImageSectionProps>
  perkIconColor?: string
}

export function mapUrlToInsideIfLoggedIn(url: string, isLoggedIn: boolean | null | undefined) {
  if (!isLoggedIn) return url
  if (url === '/wgs') {
    return '/me/order'
  }
  return url
}

export function extractSections<
  TSectionKey extends string,
  TContent extends Partial<Record<TSectionKey, LandingPageSectionFragment | undefined | null>>
>(
  content: TContent,
  sectionSpecs: SectionSpec<TSectionKey>[],
  firstImagePlacement: 'left' | 'right' = 'left',
  isLoggedIn: boolean | null = false
): ThemedTextImageSectionProps[] {
  return sectionSpecs.map(
    (
      { key, primaryButton, secondaryButton, cornerstoneType, logoAboveTitle, ...spec },
      i
    ): ThemedTextImageSectionProps => {
      const item = content[key]
      const theme = cornerstoneType ? CORNERSTONES[cornerstoneType].theme : undefined
      const buttonVariant = primaryButton?.variant ?? 'primary'
      const primaryButtonElement = primaryButton && (
        <Button
          type="button"
          size={buttonVariant === 'text' ? 'large' : ['medium', 'large']}
          tone={theme?.tone}
          leftIcon={theme?.mainIcon}
          block={buttonVariant === 'primary' ? [true, false] : false}
          {...primaryButton}
          href={
            primaryButton.href
              ? mapUrlToInsideIfLoggedIn(primaryButton.href.toString(), isLoggedIn)
              : undefined
          }
        >
          {item?.primaryButtonLabel}
        </Button>
      )

      const extraParagraphs = item?.extraParagraphsCollection?.items?.map((paragraph, i) => (
        <ContentfulContent
          key={i}
          data={paragraph?.description}
          components={{
            [BLOCKS.PARAGRAPH]: <Text variant="bodySmall" style={{ marginTop: '1rem' }} />,
          }}
        />
      ))

      const tag = <Caption>{item?.tag}</Caption>

      const children: ReactNode = (
        <>
          <div css={[noParagraphMarginCss]}>
            <ContentfulContent
              data={item?.description}
              components={{
                [BLOCKS.HEADING_6]: <Caption style={{ marginTop: '1.5rem', color: 'black' }} />,
                [INLINES.HYPERLINK]: <PrimaryLink />,
              }}
            />
            {extraParagraphs}
          </div>
          <Stack space={'1.5rem'} css={{ alignItems: 'start' }}>
            {primaryButtonElement}
            {secondaryButton && (
              <Button
                type="button"
                size="medium"
                tone={theme?.tone}
                variant="text"
                {...secondaryButton}
              >
                {item?.secondaryButtonLabel}
              </Button>
            )}
          </Stack>
        </>
      )

      const firstImageLeft = firstImagePlacement === 'left'

      return {
        logoAboveTitle: logoAboveTitle,
        title: item?.title?.replace(/\./, '.\n') ?? '',
        children,
        image: item?.image ? <ContentfulImage {...item.image} /> : null,
        imagePlacement:
          spec?.imagePlacement ?? (i % 2 === (firstImageLeft ? 0 : 1) ? 'left' : 'right'),
        theme: cornerstoneType ? CORNERSTONES[cornerstoneType].theme : undefined,
        ...(spec.props ?? {}),
        key: key,
        tag,
      }
    }
  )
}

export function extractLandingPageSections<
  TSectionKey extends string,
  TContent extends Partial<Record<TSectionKey, LandingPageSectionFragment | undefined | null>>
>(
  content: TContent,
  sectionSpecs: SectionSpec<TSectionKey>[]
): ThemedLandingPageTextImageSectionProps[] {
  return sectionSpecs.map(
    ({
      key,
      primaryButton,
      secondaryButton,
      cornerstoneType,
      perkIconColor,
    }): ThemedLandingPageTextImageSectionProps => {
      const item = content[key]
      const theme = cornerstoneType ? CORNERSTONES[cornerstoneType].theme : undefined

      return {
        title: item?.title?.replace(/\./, '.\n') ?? '',
        image: item?.image,
        imageMobile: item?.imageMobile,
        imagePlacement: item?.imagePlacement as 'left' | 'right',
        theme,
        bulletPoints: (item?.descriptionBulletPointsCollection?.items.filter(isNonNullable) ||
          ([] as unknown)) as ReactNode[],
        primaryButton: { ...primaryButton, children: item?.primaryButtonLabel },
        secondaryButton: secondaryButton
          ? { ...secondaryButton, children: item?.secondaryButtonLabel }
          : undefined,
        perkIconColor,
      }
    }
  )
}

export function extractSteps(
  tutorial?: SteppedTutorialFragment | null,
  imageSize?: { width: number; height: number }
): Step[] {
  const stepNumbers = ['1', '2', '3'] as const
  if (!tutorial) return []
  return stepNumbers.flatMap((step) => {
    const title = tutorial[`title${step}`]
    const desc = tutorial[`description${step}`]
    const image = tutorial[`image${step}`]
    const iconName = tutorial[`iconName${step}`] as IconName

    const visual = image?.url ? (
      <picture>
        <img src={image.url} {...(imageSize ?? {})} />
      </picture>
    ) : null
    if (!title || !desc || !visual) return []
    return {
      desc: (
        <div css={noParagraphMarginCss}>
          <ContentfulContent
            data={desc}
            components={{
              [INLINES.HYPERLINK]: <PrimaryLink />,
            }}
          />
        </div>
      ),
      title,
      visual,
      iconName,
    }
  })
}
