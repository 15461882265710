import { FooterNavGroup, FooterVariant } from 'logic/navigation/footer/types'
import { SUPPORT_URL, TRUST_VS_WILL_URL } from 'utils/constants'
import { matchMap } from 'utils/general'

export function describeFooterNavigation(variant: FooterVariant) {
  return {
    getGroups: () =>
      matchMap(variant, {
        full: [
          estatePlanningGroup,
          lifeStoriesGroup,
          digitalProtectionGroup,
          digitalExecutorGroup,
          resourcesGroup,
          aboutGroup,
        ],
        'estate-planning': [estatePlanningGroup, resourcesGroup, pricingGroup, companyGroup],
      }),
    shouldShowReviews: () => variant === 'estate-planning',
  }
}

const estatePlanningGroup: FooterNavGroup = {
  translationId: 'estate_planning',
  url: '/estate-planning',
  items: [
    {
      translationId: 'trust',
      url: '/trust',
    },
    {
      translationId: 'will',
      url: '/will',
    },
    {
      translationId: 'power_of_attorney',
      url: '/financial-power-of-attorney',
    },
    {
      translationId: 'funeral',
      url: '/funeral',
    },
    {
      translationId: 'healthcare_directive',
      url: '/advance-health-care-directive',
    },
    {
      translationId: 'pet_directive',
      url: '/pet-directive',
    },
  ],
}

const lifeStoriesGroup: FooterNavGroup = {
  translationId: 'life_stories',
  url: '/life-stories',
  items: [
    {
      translationId: 'story_portraits',
      url: '/story-portraits',
    },
    {
      translationId: 'singing_portraits',
      url: '/singing-portraits',
    },
    {
      translationId: 'live_portraits',
      url: '/memories',
    },
    {
      translationId: 'future_messages',
      url: '/future-message',
    },
  ],
}

const digitalProtectionGroup: FooterNavGroup = {
  translationId: 'digital_protection',
  url: '/digital-protection',
  items: [
    {
      translationId: 'digital_vault',
      url: '/digital-vault',
    },
    {
      translationId: 'dashlane_password_manager',
      url: '/dashlane-password-manager',
    },
    {
      translationId: 'cyberscout_insurance',
      url: '/cyberscout-coverage',
    },
  ],
}
const digitalExecutorGroup: FooterNavGroup = {
  translationId: 'digital_executor',
  url: '/digital-executor',
  items: [
    {
      translationId: 'digital_executor_description',
      url: '/wgs',
    },
  ],
}
const resourcesGroup: FooterNavGroup = {
  translationId: 'resources',
  items: [
    {
      translationId: 'end_of_life_guide',
      url: '/end-of-life-guide',
    },
    {
      translationId: 'articles',
      url: '/articles',
    },
    {
      translationId: 'support',
      url: SUPPORT_URL,
      target: '_blank',
    },
  ],
}

const aboutGroup: FooterNavGroup = {
  translationId: 'about',
  items: [
    {
      translationId: 'security',
      url: '/security',
    },
    {
      translationId: 'pricing',
      url: '/pricing',
    },
    {
      translationId: 'about_us',
      url: '/about',
    },
    {
      translationId: 'for_business',
      url: '/enterprise',
    },
    {
      translationId: 'contact',
      url: SUPPORT_URL,
      target: '_blank',
    },
  ],
}

const pricingGroup: FooterNavGroup = {
  translationId: 'pricing',
  items: [
    {
      translationId: 'plan_comparison',
      url: '/pricing',
    },
    {
      translationId: 'trust_vs_will',
      url: TRUST_VS_WILL_URL,
    },
  ],
}

const companyGroup: FooterNavGroup = {
  translationId: 'company',
  items: aboutGroup.items.filter((item) => item.translationId !== 'pricing'),
}
