import { SChildPathsContainer } from './Navigation.styled'
import { ChildPath, ChildPathProps } from 'components/navigation/Path'

export const BasicNavigationContent = (props: { childPaths: ChildPathProps[] }) => {
  return (
    <SChildPathsContainer>
      {props.childPaths.map((child) => (
        <ChildPath key={child.url} {...child} />
      ))}
    </SChildPathsContainer>
  )
}
