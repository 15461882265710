import { LeftColumnItem } from 'components/navigation/estatePlanning/LeftColumnItem'
import { Stack } from 'components/Stack'
import { LeftColumnItem as LeftColumnItemProperties } from 'logic/navigation/estatePlanningCard/types'
import { FC } from 'react'

export const LeftColumn: FC<{ items: LeftColumnItemProperties[] }> = ({ items }) => (
  <Stack gap={['1rem', '1.5rem']}>
    {items.map((item) => (
      <LeftColumnItem key={item.key} item={item} />
    ))}
  </Stack>
)
