import styled from '@emotion/styled'
import { BREAKPOINTS, pxToRem } from 'utils/styled'

export const SEstatePlanningNavigationCard = styled.div`
  display: grid;
  grid-template-columns: minmax(20rem, 1fr) 1px minmax(21rem, 1fr);
  max-width: min(100vw, ${pxToRem(1000)}rem);
  gap: 3rem;
  background-color: white;
  overflow: hidden;
  padding: 3rem;
  box-shadow: var(--card-shadow);

  @media ${BREAKPOINTS.MD.max} {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1px auto;
    gap: 1rem;
    box-shadow: none;
    padding: 0;
  }
`
