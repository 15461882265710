import { Divider } from 'components/navigation/estatePlanning/Divider'
import { LeftColumn } from 'components/navigation/estatePlanning/LeftColumn'
import { RightColumn } from 'components/navigation/estatePlanning/RightColumn'
import { describeNavigation } from 'logic/navigation/describe'
import { SEstatePlanningNavigationCard } from './EstatePlanningNavigationContent.styled'

export const EstatePlanningNavigationContent = () => {
  const { leftColumn, rightColumn } = describeNavigation().estatePlanningCard()

  return (
    <SEstatePlanningNavigationCard>
      <LeftColumn items={leftColumn} />
      <Divider />
      <RightColumn items={rightColumn} />
    </SEstatePlanningNavigationCard>
  )
}
