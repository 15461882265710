import { isExactLink } from 'components/ActiveLink'
import { Icon } from 'components/icon'
import { Text } from 'components/Typography'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { cloneElement, ReactElement, ReactNode } from 'react'
import { usePopper } from 'react-popper'
import { useWindowSize } from 'utils/hooks'
import { addTranslateZToStyles } from 'utils/styled'
import {
  SChildPath,
  SChildPathsWrapper,
  SPath,
  SPathNameContainer,
  SRightArrow,
  STextColumn,
  STitle,
  STitleRow,
} from './Navigation.styled'

type AuthStatus = boolean | null | undefined

export interface ChildPathProps {
  url: string
  icon?: ReactElement
  name?: ReactNode
  tag?: ReactElement
  description?: ReactNode
  isActive?: boolean
  target?: string
}

export interface PathProps {
  url?: string
  name: ReactNode
  children?: ReactElement
  isAction?: boolean
  isEncouragedAction?: boolean
  authStatus?: AuthStatus
  open?: boolean
  isSiblingOpen?: boolean
  onOpen?: (open: boolean) => void
  target?: string
  actionEvent?: () => void
}

export const Path = (props: PathProps) => {
  const { open } = props
  const router = useRouter()
  const isActive = props.url ? isExactLink({ href: props.url }, router) : false
  const [referenceElement, setReferenceElement] = React.useState<HTMLAnchorElement | null>(null)
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 16,
        },
      },
    ],
  })

  const content = (
    <SPath
      ref={setReferenceElement}
      hasUrl={!!props.url}
      isEncouragedAction={props.isEncouragedAction}
      isActive={isActive}
      onClick={props?.actionEvent}
      onMouseEnter={() => {
        props.onOpen?.(true)
      }}
      onMouseLeave={() => {
        props.onOpen?.(false)
      }}
      as={props.url ? 'a' : 'span'}
    >
      <SPathNameContainer>
        <Text>{props.name}</Text>
        {!!props.children && (
          <Icon name="ChevronDown" size=".75rem" css={{ marginLeft: '.4rem' }} />
        )}
      </SPathNameContainer>

      {props.children && (
        <SChildPathsWrapper
          noTransition={props.isSiblingOpen}
          ref={setPopperElement}
          {...attributes.popper}
          style={styles.popper && addTranslateZToStyles(styles.popper, 99)}
          show={open}
        >
          {props.children}
        </SChildPathsWrapper>
      )}
    </SPath>
  )

  return props.url ? (
    <Link href={props.url} passHref legacyBehavior>
      {content}
    </Link>
  ) : (
    content
  )
}

export const ChildPath = (props: ChildPathProps) => {
  const { isDesktop } = useWindowSize()

  return (
    <Link href={props.url} passHref legacyBehavior>
      <SChildPath isActive={props.isActive} target={props.target}>
        {props.icon}
        <STextColumn>
          <STitleRow>
            <STitle variant={isDesktop ? 'bodyBold' : undefined}>{props.name}</STitle>
            {props.tag && cloneElement(props.tag, { style: { marginLeft: '0.5rem' } })}
            <SRightArrow name="CaretRight" className="desktop-only" />
          </STitleRow>
          <Text className="desktop-only">{props.description}</Text>
        </STextColumn>
      </SChildPath>
    </Link>
  )
}
