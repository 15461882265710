import { describeNavigationEstatePlanningCard } from 'logic/navigation/estatePlanningCard/describe'
import { describeFooterNavigation } from 'logic/navigation/footer/describe'
import { getLandingPageNavigationStructure } from 'logic/navigation/getLandingPageNavigationStructure'

export function describeNavigation() {
  return {
    getLandingPageStructure: getLandingPageNavigationStructure,
    estatePlanningCard: describeNavigationEstatePlanningCard,
    footer: describeFooterNavigation,
  }
}
