import { FC } from 'react'
import { colors } from 'utils/colors'

export const Divider: FC = () => (
  <div
    css={{
      width: '100%',
      height: '100%',
      backgroundColor: colors.blue[100],
    }}
  />
)
