import styled from '@emotion/styled'
import { Text } from 'components/Typography'
import { StyledLinkProps } from 'utils/styled'
import { BasicLink } from 'components/Link'

export const StyledContentText = styled(Text)`
  display: block;
  margin: 1.75rem 0;

  li & {
    margin: 0;
  }
`

export const StyledContentImage = styled.img`
  margin: 2rem 0;
  max-width: 100%;
  display: block;
`

export const StyledContentOrderedList = styled.ol<StyledLinkProps>`
  padding-left: 1.5rem;
  font-family: var(--f-text);

  & > li {
    padding-left: 0.25rem;

    ol {
      margin: 0;
    }
  }

  & li ol {
    list-style-type: upper-alpha;

    & li ol {
      list-style-type: lower-roman;

      & li ol {
        list-style-type: lower-alpha;
      }
    }
  }
`

export const StyledContentUnorderedList = styled.ul<StyledLinkProps>`
  padding-left: 1.5rem;
  font-family: var(--f-text);
  color: var(--c-gray500);
`

export const StyledContentLink = styled(BasicLink)`
  outline: none;

  &:active,
  &:hover {
    color: var(--c-blue500);
  }
`
export const StyledIFrameContainer = styled.span`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 56.25%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    border: none;
  }
`
