import { FC } from 'react'
import { isNonNullable } from 'utils/general'

export type OnlyProps = {
  on: 'mobile' | 'desktop'
  className?: string
}

export const Only: FC<React.PropsWithChildren<OnlyProps>> = (props) => {
  return (
    <div className={[`${props.on}-only`, props.className].filter(isNonNullable).join(' ')}>
      {props.children}
    </div>
  )
}
