import { Icon } from 'components/icon'
import { HStack } from 'components/Stack'
import { Text } from 'components/Typography'
import { RightColumnItem as RightColumnItemProperties } from 'logic/navigation/estatePlanningCard/types'
import { Trans } from 'next-i18next'
import Link from 'next/link'
import { FC } from 'react'
import { colors } from 'utils/colors'
import styled from '@emotion/styled'
import { BREAKPOINTS } from 'utils/styled'

export const RightColumnItem: FC<{ item: RightColumnItemProperties }> = ({ item }) => (
  <Link href={item.url} passHref legacyBehavior>
    <SColumns gap={['0.75rem', '1rem']}>
      <div
        css={[
          BREAKPOINTS.MD.css({ width: '2rem' }),
          { display: 'flex', flexShrink: 0, alignItems: 'center', justifyContent: 'center' },
        ]}
      >
        <Icon name={item.icon} size="1rem" />
      </div>
      <Text variant={['bodyLarge', 'bodyLargeBold']} css={{ color: colors.black }} data-is-title>
        <Trans i18nKey={`common.navigation.estate_planning.${item.type}.title`} />
      </Text>
    </SColumns>
  </Link>
)

const SColumns = styled(HStack)({
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    '*[data-is-title]': {
      textDecoration: 'underline',
    },
  },
})
