import { CORNERSTONES } from 'components/cornerstone/CornerstoneUtils'
import { IconWrapper } from 'components/iconWrapper/IconWrapper'
import { Caption } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useWindowSize } from 'utils/hooks'
import {
  SCornerstone,
  SCornerstoneChildPath,
  SCornerstoneLinksContainer,
  SCornerstonesContainer,
  SCornerstoneTitle,
} from './Navigation.styled'

export const ProductNavigationContent = () => {
  const { t } = useTranslation()
  const { isDesktop } = useWindowSize()
  const isMobile = !isDesktop

  return (
    <SCornerstonesContainer>
      {Object.values(CORNERSTONES).map((cornerstone) => (
        <SCornerstone key={cornerstone.translationId}>
          <Link href={cornerstone.url} passHref legacyBehavior>
            <SCornerstoneTitle css={{ color: cornerstone.theme.main }}>
              <IconWrapper size={36} backgroundColor={cornerstone.theme.main}>
                {cornerstone.theme.mainIcon}
              </IconWrapper>
              <Caption css={{ color: cornerstone.theme.main }}>
                {t(`common.navigation.cornerstones.${cornerstone.translationId}.title`)}
              </Caption>
            </SCornerstoneTitle>
          </Link>
          <SCornerstoneLinksContainer>
            {cornerstone.children?.map((child, index) => {
              const name = t(`common.navigation.cornerstone_children.${child.translationId}.title`)
              const mobileName = child?.mobileTranslationId
                ? t(`common.navigation.cornerstone_children.${child?.mobileTranslationId}.title`)
                : undefined
              return (
                <Link key={index} href={child.url || cornerstone.url} passHref legacyBehavior>
                  <SCornerstoneChildPath>
                    {isMobile ? mobileName ?? name : name}
                  </SCornerstoneChildPath>
                </Link>
              )
            })}
          </SCornerstoneLinksContainer>
        </SCornerstone>
      ))}
    </SCornerstonesContainer>
  )
}
