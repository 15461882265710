import {
  documentToReactComponents,
  Options,
  RenderNode,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { IContentfulRichText } from 'api/contentful/contentful.types'
import {
  StyledContentImage,
  StyledContentLink,
  StyledContentOrderedList,
  StyledContentText,
  StyledContentUnorderedList,
  StyledIFrameContainer,
} from './ContentfulContent.styled'
import {
  Title,
  Title_h1_default,
  Title_h2_default,
  Title_h3_default,
  Title_h4_default,
} from 'components/Typography'
import { cloneElement, createContext, ReactElement, useContext } from 'react'
import { encodeQuery } from 'utils/fetcher'

interface IAssetContext {
  links: IContentfulRichText['links'] | null
  imageQuery?: { [key: string]: string | number } | null
}

const LinkContext = createContext<IAssetContext | null>(null)

function AssetNode({ linkId }: { linkId?: string }) {
  const context = useContext(LinkContext)
  const image =
    linkId != null && context?.links?.assets.block.find((item) => item.sys.id === linkId)

  if (!image) return null
  return <StyledContentImage src={encodeQuery(image.url, context?.imageQuery ?? {})} />
}

function YoutubeNode({ uri }: { uri?: string }) {
  if (!uri) return null
  return (
    <StyledIFrameContainer>
      <iframe allowFullScreen src={uri} />
    </StyledIFrameContainer>
  )
}

enum CUSTOMS {
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
}

interface IContentfulComponents {
  [BLOCKS.PARAGRAPH]?: ReactElement
  [BLOCKS.HEADING_1]?: ReactElement
  [BLOCKS.HEADING_2]?: ReactElement
  [BLOCKS.HEADING_3]?: ReactElement
  [BLOCKS.HEADING_4]?: ReactElement
  [BLOCKS.HEADING_5]?: ReactElement
  [BLOCKS.HEADING_6]?: ReactElement
  [BLOCKS.UL_LIST]?: ReactElement
  [BLOCKS.OL_LIST]?: ReactElement
  [BLOCKS.EMBEDDED_ASSET]?: ReactElement
  [INLINES.HYPERLINK]?: ReactElement
  [CUSTOMS.YOUTUBE_VIDEO]?: ReactElement
}

const defaultComponents: Required<IContentfulComponents> = {
  [BLOCKS.PARAGRAPH]: <StyledContentText as={'p'} />,
  [BLOCKS.HEADING_1]: <Title variant={Title_h1_default} as="h1" css={{ margin: '2rem 0' }} />,
  [BLOCKS.HEADING_2]: <Title variant={Title_h2_default} as="h2" css={{ margin: '2rem 0' }} />,
  [BLOCKS.HEADING_3]: <Title variant={Title_h3_default} as="h3" css={{ margin: '1.5rem 0' }} />,
  [BLOCKS.HEADING_4]: <Title variant={Title_h4_default} as="h4" css={{ margin: '1rem 0' }} />,
  [BLOCKS.HEADING_5]: <Title variant={'h5'} as="h5" css={{ margin: '1rem 0' }} />,
  [BLOCKS.HEADING_6]: <Title variant={'h6'} as="h6" css={{ margin: '1rem 0' }} />,
  [BLOCKS.UL_LIST]: <StyledContentUnorderedList />,
  [BLOCKS.OL_LIST]: <StyledContentOrderedList />,
  [BLOCKS.EMBEDDED_ASSET]: <AssetNode />,
  [INLINES.HYPERLINK]: <StyledContentLink target="_blank" rel="noreferrer" />,
  [CUSTOMS.YOUTUBE_VIDEO]: <YoutubeNode />,
}

function resolveNode(
  key: keyof IContentfulComponents,
  components?: IContentfulComponents,
  fallback = defaultComponents
) {
  return components?.[key] ?? fallback[key]
}

export function ContentfulContent(props: {
  data?: IContentfulRichText | null
  imageQuery?: IAssetContext['imageQuery']
  components?: IContentfulComponents
}) {
  const renderOptions: Options = {
    renderNode: {
      ...(Object.keys(defaultComponents) as (keyof IContentfulComponents)[]).reduce<RenderNode>(
        (memo, key) => {
          memo[key] = (_, children) =>
            cloneElement(resolveNode(key, props.components), {}, children)

          return memo
        },
        {}
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const linkId = node.data?.target?.sys?.id
        if (typeof linkId !== 'string') {
          return null
        }

        return cloneElement(resolveNode(BLOCKS.EMBEDDED_ASSET, props.components), { linkId })
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return node.data.uri.includes('youtube.com/embed')
          ? cloneElement(
              resolveNode(CUSTOMS.YOUTUBE_VIDEO, props.components),
              { uri: node.data.uri },
              children
            )
          : cloneElement(
              resolveNode(INLINES.HYPERLINK, props.components),
              { href: node.data.uri },
              children
            )
      },
    },
  }

  return (
    <LinkContext.Provider
      value={{
        links: props.data?.links ?? null,
        imageQuery: props.imageQuery,
      }}
    >
      {props.data?.json && documentToReactComponents(props.data.json, renderOptions)}
    </LinkContext.Provider>
  )
}
