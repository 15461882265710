import { getDirectiveSpec } from 'components/directive/utils/directiveSpecs'
import { DirectiveType } from 'components/directive/utils/types'
import { NavigationEstatePlanningCardDescription } from 'logic/navigation/estatePlanningCard/types'
import { colors } from 'utils/colors'

export function describeNavigationEstatePlanningCard(): NavigationEstatePlanningCardDescription {
  return {
    leftColumn: [
      { ...directiveItem('WILL'), color: colors.blue[500] },
      { ...directiveItem('TRUST'), color: colors.trust.primary },
    ],
    rightColumn: [
      { ...directiveItem('POWER_OF_ATTORNEY') },
      { ...directiveItem('MEDICAL') },
      { ...directiveItem('FUNERAL') },
      { ...directiveItem('PET') },
      digitalVaultItem(),
    ],
  }
}

function directiveItem<T extends DirectiveType>(type: T) {
  const { icon, landingPagePath } = getDirectiveSpec(type)
  return { key: type, type, icon, url: landingPagePath }
}

function digitalVaultItem() {
  return {
    key: 'DIGITAL_VAULT',
    type: 'DIGITAL_VAULT' as const,
    icon: 'DigitalVault' as const,
    url: '/digital-vault',
  }
}
